import { createRouter, createWebHistory } from 'vue-router';
import LoginForm from './components/LoginForm.vue';
import JobsList from './components/JobsList.vue';
import ParseItem from './components/ParseItem.vue';
import ProfileForm  from './components/ProfileForm.vue';

const routes = [
  { path: '/', component: LoginForm, meta: { showSidebar: false, title: "Bid Alert" } },
  { path: '/jobs', component: JobsList, meta: { showSidebar: true, title: "Bid Alert" } },
  { path: '/parse-item', component: ParseItem, meta: { showSidebar: true, title: "Bid Alert" } },
  { path: '/profile', component: ProfileForm, meta: { showSidebar: true, title: "Bid Alert" } },  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
