
<template>
    <div class="overlay" v-if="isLoading">
        <div class="loader">
            <div class="spinner"></div>
            <div class="loader-text">{{ loaderText }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoading: {
            type: Boolean,
            required: true
        },
        loaderText: {
            type: String,
            default: 'Traitement de la demande en cours' // Texte par défaut
        }
    }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader-text {
    color: white;
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
}

.spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-top: 8px solid white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>
