<template>

  <div>
      <!-- Contenu de la page -->
      <LoaderDiv :isLoading="loading"  loaderText="Chargement en cours, veuillez patienter..." />
  </div>

  <div>
    <h2>Liste des actions planifiées</h2>
    <div>Cette écran affiche la liste des actions planifiées. Chacune de ces tache est planifiée 5 minutes avant la fin d'une enchère et envoi une notification sur un appareil mobile </div>

    <table>
      <thead>
        <tr>
          <th>Lien source</th>
          <th>Lien zenmarket (si disponible)</th>
          <th>Identifiant de l'objet</th>
          <th>Autres suiveurs</th>
          <th>Date et heure de la notification</th>
          <th>Statut</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="job in jobs" :key="job.job_id">
          <!-- <td>{{ job.job_id }}</td> -->
          <td><a target="_blank" :href="'https://page.auctions.yahoo.co.jp/jp/auction/' + job.item_id">{{ job.item_id
              }}</a></td>
          <td><a target="_blank" :href="'https://zenmarket.jp/fr/auction.aspx?itemCode=' + job.item_id">{{ job.item_id
              }}</a></td>


          <td>{{ job.item_id }}</td>
          <td>
            <!-- Vérifiez si l'attribut existe et s'il contient le tableau -->
            <div v-if="job.followers && job.followers.person">
                <div v-for="(item, index) in job.followers.person" :key="index">
                  {{ item }}
                </div>
              </div>

              <!-- Afficher un message si le tableau n'existe pas -->
              <div v-else>
                
              </div>            
          </td>
          <td>{{ job.notify_time }}</td>
          <td>{{ job.status }}</td>
          <td>
            <button @click="confirmCancel(job.job_id)">Annuler</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';
import { isTokenExpired } from '../auth';
import LoaderDiv from './LoaderDiv.vue'; // Chemin vers votre composant Loader


export default {
  components: {
      LoaderDiv
  },
  name: 'JobsList',  // Ici, le nom du composant doit être multi-mots
  data() {
    return {
      jobs: [],
      loading: true // Ajout de la variable pour le chargement
    };
  },
  async created() {
    const token = localStorage.getItem('access_token');
    if (isTokenExpired(token)) {
      // Rediriger vers la page de login si le token est expiré
      this.$router.push('/');
    }

    try {
      // Activer le loader
      this.loading = true;

      const response = await axios.get('https://collection.prexens.com/jobs');
      this.jobs = response.data;

    } catch (error) {
      console.error("Erreur lors de la récupération des jobs:", error);
    } finally {
      // Désactiver le loader
      this.loading = false;
    }
  },
  methods: {
    // Fonction pour confirmer l'annulation du job
    confirmCancel(jobId) {
      if (confirm(`Es-tu sûr de vouloir annuler le job ${jobId} ?`)) {
        this.cancelJob(jobId);
      }
    },
    // Fonction pour annuler un job
    async cancelJob(jobId) {
      const token = localStorage.getItem('access_token');
      try {
        await axios.post(`https://collection.prexens.com/jobs/${jobId}/cancel`, {}, {
          headers: { Authorization: `Bearer ${token}` }
        });
        alert(`Le job ${jobId} a été annulé avec succès.`);
        // Met à jour la liste des jobs (optionnel, en fonction de ta logique de suppression)
        this.jobs = this.jobs.filter(job => job.job_id !== jobId);
      } catch (error) {
        console.error("Erreur lors de l'annulation du job:", error);
        alert("Erreur lors de l'annulation du job.");
      }
    }
  }
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  /* background-color: #f2f2f2; */
  font-weight: normal;
  background-color: #3498db;
  color: white
}

button {
  padding: 6px 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #ff1a1a;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  text-align: center;
  font-size: 18px;
  color: #3498db;
}
</style>
